import { Button, Popconfirm, Tag, Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { useScopeInfo } from '../../Hooks/useScopeInfo'
import { AssignmentRequestActionsProps } from '../ButtonsAssignmentRequestActions'
import { useReleaseAssignedRequest } from './useReleaseAssignedRequest'
import { printAssignmentRequestActionError } from './printAssignmentRequestActionError'
import { UserDeleteOutlined } from '@ant-design/icons'
import { stringify } from 'qs'
import { useAssignmentRequest } from '../useAssignmentRequest'
import { useRouter } from '../../Hooks/useRouter'

export function ButtonReleaseAssignedRequest(props: AssignmentRequestActionsProps) {
  const t = useTranslation()
  const { scope } = useScopeInfo()
  const { loading, error, handleReleaseAssignedRequest } = useReleaseAssignedRequest()
  const router = useRouter()
  const { isRegistrarAssignedUser, isRegistryAssignedUser } = useAssignmentRequest()

  const hasPermission =
    scope === 'registrar'
      ? !(props.isAssignedUser || isRegistrarAssignedUser)
      : !(props.isAssignedUser || isRegistryAssignedUser)

  if (error) {
    return <Tag color={'red'}>{printAssignmentRequestActionError({ error })}</Tag>
  }

  const handleOnClick = () => {
    handleReleaseAssignedRequest(props.requestId, scope).then(() => {
      if (props.onSuccess) {
        props.onSuccess && props.onSuccess()
      } else {
        router.history.push(
          router.pathname + '?' + stringify({ ...router.query, reload: new Date() })
        )
      }
    })
  }

  return (
    <Tooltip title={t('assignmentRequests.action.releaseAssignedRequest')}>
      <Popconfirm title={t("assignmentRequests.message.releaseAssignedRequest")}
                  onConfirm={handleOnClick}
                  okText={t('yes')}
                  cancelText={t('no')}>
      <Button
        icon={<UserDeleteOutlined />}
        type={'link'}
        disabled={hasPermission}
        loading={loading}
      />
      </Popconfirm>
    </Tooltip>
  )
}
