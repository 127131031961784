import React, { useEffect, useState } from 'react'
import { Form, Select, Space, Spin } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { RegistrarUserListService, RegistryUserListService } from '@nic/nic-api'
import { Security } from '../../../../nic-react-monorepo/packages/nic-api/types'
import { printAxiosError } from '../../Utils/common'
import { useScopeInfo } from '../Hooks/useScopeInfo'
import axios from 'axios'
import { useTranslation } from '@nic/commons'

const { Option } = Select

const UserSelect: React.FC<{ onSelect: any }> = ({ onSelect }) => {
  const [users, setUsers] = useState<Security.User[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filteredUsers, setFilteredUsers] = useState<Security.User[] | null>([])
  const [error, setError] = useState<any>(null)
  const { scope } = useScopeInfo()
  const t = useTranslation()
  useEffect(() => {
    // Simulating API call, replace with your actual API endpoint
    const fetchData = async () => {
      setLoading(true)
      try {
        // Replace the following URL with your actual API endpoint
        const response =
          scope === 'registrar'
            ? await RegistrarUserListService({ page: 0, size: 100 })
            : await RegistryUserListService({ registrarId: 3140, page: 0, size: 100 } as any)
        const _data = await response.data.elements
        const data =
          scope === 'registrar'
            ? _data
            : _data.filter((v) => !['system', 'REGISTRY-MNT'].includes(v.username))
        setUsers(data)
        setFilteredUsers(data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [scope])

  const handleClear = () => {
    setFilteredUsers(users)
  }

  const handleFilter = (inputValue: string) => {
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(inputValue.toLowerCase()),
    )
    setFilteredUsers(filtered)
  }

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={printAxiosError403(error,t)}>
      <Select
        showSearch
        labelInValue
        placeholder='Select a user'
        optionFilterProp='children'
        filterOption={false}
        onSearch={handleFilter}
        onSelect={onSelect}
        loading={loading}
        allowClear={true}
        onClear={handleClear}
        style={{ width: 400 }}
        notFoundContent={loading ? <Spin size='small' /> : null}
      >
        {filteredUsers?.map((user) => (
          <Option key={user.id} value={user.username}>
            <div>
              <Space>
                <UserOutlined style={{ marginRight: 8 }} />
                {user.username}{' '}
                {user.name && (
                  <>
                    {' '}
                    - <i>({user.name})</i>
                  </>
                )}
              </Space>
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default UserSelect


function printAxiosError403(e: any,t:any) {
  if (e) {
    if (axios.isAxiosError(e) && e.response?.status === 403) {
      return <div>{t("assignmentRequests.403errorMessage")}</div>
    }

    return printAxiosError(e)
  }
  return null
}
