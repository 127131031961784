import StatusView from '../Status/StatusView'
import {
  countRejectedRequests,
  RejectedRequestBadgedLabel,
} from '../Status/RejectedRequestBadgedLabel'
import { AssignmentRequestActions } from '../AssignmentRequests/ButtonsAssignmentRequestActions'
import { stdDateHelper } from '../../Utils/DateUtils'
import { RequestReferenceItem } from './ReferenceItem'
import { IRequestTableData } from './RequestsTable'
import { Link } from 'react-router-dom'
import { Darwin } from '@nic/nic-api/types'
import { IReferenceContact } from '@nic/nic-api/types/Darwin/types'
import { Registrar, UserName } from '@nic/ui-antd'
import { Typography } from 'antd'

const { Text } = Typography

export const requestsTableRegistry = (realm: any, data: any, keycloak: any) => {
  return {
    id: (id: number) => <Link to={`/${realm}/requests/${id}`}>{id}</Link>,
    registrarId: (id: number) => (
      <Registrar
        id={id}
        render={(d: any) => <div style={{ whiteSpace: 'nowrap' }}>{d.name}</div>}
      />
    ),
    userId: (id: number) => <UserName userId={id} />,
    status: (status: Darwin.RequestStatus, record: IRequestTableData) => {
      return <StatusView status={status} />
    },
    extra: (status: Darwin.RequestStatus, record: IRequestTableData) => {
      if (['pending', 'registrarUpdated'].includes(status)) {
        const currentRow = data?.filter((d: any) => d.id === record.id)[0]
        return (
          <RejectedRequestBadgedLabel count={countRejectedRequests(currentRow?.events as any)} />
        )
      }
      return <></>
    },
    referenceContact: (referenceContact: IReferenceContact) =>
      referenceContact && (
        <div style={{ minWidth: '200px' }}>{RequestReferenceItem(referenceContact)}</div>
      ),
    action: (text: any, record: IRequestTableData) => {
      const currentRow = data?.filter((d: any) => d.id === record.id)[0]
      if (currentRow?.status === 'pending' || currentRow?.status === 'registrarUpdated') {
        return (
          <div style={{ float: 'right' }}>
            <AssignmentRequestActions
              isAssignedUser={
                keycloak?.tokenParsed?.userId === currentRow?.registryUser?.id
              }
              requestId={record.id}
            />
          </div>
        )
      } else {
        return <></>
      }
    },
    created: (created: string) => <span>{stdDateHelper(created)}</span>,
    domains: (value: any, record: any) => {
      return <div style={{ textAlign: 'left' }}>{value}</div>
    },
    // events: (value: Darwin.RequestEvents, record: any) => {
    //   return (
    //     <div style={{ textAlign: "left" }}>
    //       {countRejectedRequests(value)}
    //     </div>
    //   );
    // },
    registrarUser: (value: Darwin.RegistrarUser, record: IRequestTableData) => (
      <div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
        {value ? (
          <Text strong style={{ color: '#B2B2B2' }}>
            {value.username}
          </Text>
        ) : (
          <></>
        )}
      </div>
    ),
    registryUser: (value: Darwin.RegistrarUser, record: IRequestTableData) => (
      <div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
        {value ? <Text strong>{value.username}</Text> : <></>}
      </div>
    ),
    contacts: (value: number, record: IRequestTableData) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  }
}
