import { useContext } from 'react'
import { RequestContext } from '../Contexts/RequestContext'
import { userDarwinRole } from '../Layout/UserInfo'
import { useAuth } from '@nic/kc-auth'

/**
 * hook usato per determinare le associazioni delle richieste tra registry e registrar
 *
 */
export function useAssignmentRequest() {
  const { keycloak } = useAuth()
  const { stateReqById } = useContext(RequestContext)
  let userRole = userDarwinRole(keycloak)

  const isRegistrarAssignedUser =
    keycloak?.tokenParsed?.userId === stateReqById?.data?.registrarUser?.id

  const isRegistrarAssigned = !!stateReqById?.data?.registrarUser?.id

  const isRegistryAssignedUser =
    keycloak?.tokenParsed?.userId === stateReqById?.data?.registryUser?.id

  const isRegistryAssigned = stateReqById?.data?.registryUser?.id !== undefined

  const isUserAdmin =
    userRole === 'admin' ||
    userRole === 'tech-admin' ||
    userRole === 'operator-admin' ||
    userRole === 'legal-admin' ||
    userRole === 'billing-admin'

  const isUserDomain = userRole === 'domain'
  const isUserDarwin = userRole === 'darwin'
  const isUserDarwinAdmin = userRole === 'darwin-admin'

  const registrarStatus: 'assignedTo' | 'assignedToMe' | undefined = isRegistrarAssignedUser
    ? 'assignedToMe'
    : isRegistrarAssigned
    ? 'assignedTo'
    : undefined

  const registryStatus: 'assignedTo' | 'assignedToMe' | undefined = isRegistryAssignedUser
    ? 'assignedToMe'
    : isRegistrarAssigned
    ? 'assignedTo'
    : undefined

  return {
    /** ruolo dell'utente (domain|admin|darwin|darwin-admin|tech-admin)**/
    userRole,
    /** la richiesta è già stata assegnata ad un utente Registrar **/
    isRegistrarAssigned,
    /** la richiesta è già stata assegnata ad un utente Registrar e quello attuale coincide **/
    isRegistrarAssignedUser,
    /** stato dell'operatore registrar **/
    registrarStatus,
    /** la richiesta è assegnata ad un utente Registry **/
    isRegistryAssigned,
    /** la richiesta è assegnata ad un utente Registry e quello attuale coincide **/
    isRegistryAssignedUser,
    /** stato dell'operatore registry **/
    registryStatus,
    /** l'utente è di tipo darwin**/
    isUserDarwin,
    /** l'utente è di tipo admin**/
    isUserAdmin,
    /** l'utente è di tipo domain**/
    isUserDomain,
    /** l'utente è di tipo darwin admin**/
    isUserDarwinAdmin,
  }
}
